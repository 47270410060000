/*-----------------------------------------------------------------------------------

	content.less

-----------------------------------------------------------------------------------*/


/* ----------------------------------------------------------------
	Content
-----------------------------------------------------------------*/


#content {
	position: relative;
	overflow: hidden;
	background-color: #FFF;

	p {
		line-height: @line-height-content;
	}

	.content-wrap {
		position: relative;
		padding: 80px 0;
	}

	.container {
		position: relative;
	}
}

