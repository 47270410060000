#section-intro-logo {
    background: url('gfx/bg-top.png') repeat-x top center;
    height: 169px;
    padding-top:70px;
}
#primary-menu ul li.current {
        background-color: #054c80;
}
#primary-menu ul li a {
    padding:0 20px;
}
#intro-home {
    h1 {
        font-weight:300;
        font-family: 'Merriweather', serif;
        font-size: 40px;
        line-height: 50px;
        
    }
    p {
        border-top: 1px solid #acb1b5;
        width:55%;
        text-align: center;
        margin: 0 auto; 
        padding-top: 15px;
        font-size:19px;
        color: #424242;
        font-family: 'Merriweather', serif;
        font-weight: 300;
    }
}

.border-top {
    border-top: 10px solid #054c80;    
}
p {
        text-align: center;
        padding:8px 0;
        margin: 0;
        line-height: 24px;
    }
#section-about, #section-team {
    
    .divider {
        margin: 10px 0;
    }
}

.box-radca {
    text-align:center; 
    font-weight:bold;
    font-size: 18px;
    font-family: 'Merriweather', serif;
    
    img {
        padding: 0 7px;
        margin-top: -3px;
    }
}
.bg2 {
    background-color: @bg2;
}

.modal {
  text-align: center;
  z-index:99999999;
}

@media screen and (min-width: 768px) { 
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
    z-index:99999999;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.modal-title h4 {
    font-weight: 400;
}/*
.modal-lg {
    positon: fixed;
    z-index:99999999;
}
.modal-body {
    z-index: 99999!important;
  //  position:absolute;
}
*/
.modal-content {
    border-radius: 0;
}


#section-work {
    text-align: center;
    
    .divider {
        color: #d5dce1;
        margin:20px 0;
    }
    .divider:after, .divider.divider-center:before, .divider.divider-center.divider-short:before {
        border-top: 1px solid #d5dce1;
    }
    
    ul.tab-nav li a {
        padding:0 35px;
    }
    
    .tab-nav li {
        float:none;
        display:inline-block;
    }
    .nav-tabs > li, .nav-pills > li {
        float:none;
        display:inline-block;
        
    }
    
    .nav-tabs, .nav-pills {
        text-align:center;
    }
    
    .feature-box.fbox-border.fbox-light .fbox-icon {
        background-color: #F5F5F5;
    }
    .postcontent, .sidebar, .col_full, .col_half, .col_one_third, .col_two_third, 
    .col_three_fourth, .col_one_fourth, .col_one_fifth, .col_two_fifth, 
    .col_three_fifth, .col_four_fifth, .col_one_sixth, .col_five_sixth  {
        margin-bottom:30px;
        }
        
    .feature-box h3 {
        text-transform: none;
        font-weight: 400;
        letter-spacing: 0;
        a {
            color: #5e5b5b;
        }
    }
    .feature-box.fbox-center:not(.fbox-bg) h3:after {
        display:none;
    }
}

#section-team {
    p {
        text-align: left;
    }
}
.img-fleft {
    float: left;
    padding: 10px 50px 20px 0;
}
.img-fright {
    float: right;
    padding: 10px 0 20px 50px;
}

.toppadding-md {
    padding-top:60px;
}
.bottompadding-md {
    padding-bottom:60px;
}

#gotoTop {
    border-radius: 100%;
}
.sm-form-control{
    border: 1px solid #DDD;
}

#footer.dark, .dark #footer {
    background-color: #012c54;
    font-family: 'Merriweather', serif;
    font-weight:300;
    color: #c0ccd7;
    font-size: 13px;
    line-height:22px;
    
    b {
        font-weight: 400;
    }
    ul {
        margin-bottom: 0;
    }
    span {
        color: #fff;    
        
        b {
            font-size: 15px;
        }
    }
    .foot-big {
        font-size: 18px;
        line-height:30px;
        
        span {
            font-size: 22px;
            
        }
        a {
            color: #fff;
        }
        i {
            padding-right: 10px;
            color: #265683;
        }
    }
}

ul, ul li {
    list-style-position: inside;
}
#copyrights {
    font-size: 14px; 
    padding: 20px 0;
    
    img {
        
        padding-left:10px;
    }
    a {
        color: #fff;
        opacity: 0.4;
        transition: all 0.2s ease-in-out;
    }
    a:hover {
        opacity: 1;
    }
}

body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu > ul > li:hover a, 
body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu > ul > li.current a, 
body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu > div > ul > li:hover a, 
body:not(.dark) #header:not(.dark) #header-wrap:not(.dark) #primary-menu > div > ul > li.current a {
    color: #fcf29f!important;
}



@media (max-width: 767px) {  
    body {
        font-size: 14px;
    }
    #intro-home h1 {
        font-size: 26px;
        line-height: 32px;
    }
    #intro-home p {
        width:100%;
        font-size: 14px;
    }
    #section-work .divider {
        display: none;
    }
    .img-fleft {
        padding:0 20px 20px 20px;
        float: left;
        max-width:40%;
    }
}

@media (max-width: 479px) {  
    .img-fleft {
        padding:0 0 20px 0;
        float: none;
        max-width:100%;
    }
    #section-team p {
        text-align: center;
    }
    #footer.dark .foot-big span, .dark #footer .foot-big span{
        font-size: 16px;
    }
}