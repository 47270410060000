/* ********** PADDING ********** */

@pad-no:    0;
@pad-xs:    10px;
@pad-sm:    20px;
@pad-md:    40px;
@pad-lg:    60px;


/*  extra small */
.pad-t-no {
    padding-top: @pad-no;
}
.pad-b-no {
    padding-bottom: @pad-no;
}
.pad-l-no {
    padding-left: @pad-no;
}
.pad-r-no {
    padding-right: @pad-no;
}
.pad-tb-no, .pad-bt-no {
    padding-top: @pad-no;
    padding-bottom: @pad-no;
}
.pad-lr-no, .pad-rl-no {
    padding-left: @pad-no;
    padding-right: @pad-no;
}


/*  extra small */
.pad-t-xs {
    padding-top: @pad-xs;
}
.pad-b-xs {
    padding-bottom: @pad-xs;
}
.pad-l-xs {
    padding-left: @pad-xs;
}
.pad-r-xs {
    padding-right: @pad-xs;
}
.pad-tb-xs, .pad-bt-xs {
    padding-top: @pad-xs;
    padding-bottom: @pad-xs;
}
.pad-lr-xs, .pad-rl-xs {
    padding-left: @pad-xs;
    padding-right: @pad-xs;
}



/*  small */
.pad-t-sm {
    padding-top: @pad-sm;
}
.pad-b-sm {
    padding-bottom: @pad-sm;
}
.pad-l-sm {
    padding-left: @pad-sm;
}
.pad-r-sm {
    padding-right: @pad-sm;
}
.pad-tb-sm, .pad-bt-sm {
    padding-top: @pad-sm;
    padding-bottom: @pad-sm;
}
.pad-lr-sm, .pad-rl-sm {
    padding-left: @pad-sm;
    padding-right: @pad-sm;
}

/*  medium */
.pad-t-md {
    padding-top: @pad-md;
}
.pad-b-md {
    padding-bottom: @pad-md;
}
.pad-l-md {
    padding-left: @pad-md;
}
.pad-r-md {
    padding-right: @pad-md;
}
.pad-tb-md, .pad-bt-md {
    padding-top: @pad-md;
    padding-bottom: @pad-md;
}
.pad-lr-md, .pad-rl-md {
    padding-left: @pad-md;
    padding-right: @pad-md;
}

/* large */
.pad-t-lg {
    padding-top: @pad-lg;
}
.pad-b-lg {
    padding-bottom: @pad-lg;
}
.pad-l-lg {
    padding-left: @pad-lg;
}
.pad-r-lg {
    padding-right: @pad-lg;
}
.pad-tb-lg, .pad-bt-lg {
    padding-top: @pad-lg;
    padding-bottom: @pad-lg;
}
.pad-lr-lg, .pad-rl-lg {
    padding-left: @pad-lg;
    padding-right: @pad-lg;
}